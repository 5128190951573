@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --color-theme: 38, 188, 98, 1;
        --color-theme-gradient: 0, 128, 38, 1;
    }

    .no-outline:focus {
        outline: none;
    }

    .promo-code-hidden {
        @apply -mt-[6.5rem] mb-6 2xs:mb-4
    }

    .promo-code-shown {
        @apply -mt-5
    }

    .invalid-promo-code-bounce {
        animation: bounce 10s;

        animation-iteration-count: infinite;
    }

    @keyframes bounce {

        0%,
        10%,
        20%,
        30%,
        40%,
        50%,
        100% {
            transform: translateY(0);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }

        5%,
        15%,
        25%,
        35%,
        45% {
            transform: translateY(-25%);
            animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        }
    }
}

@layer components {
    .card-blocked-container {
        @apply relative z-10
    }

    .card-blocked-area {
        @apply relative max-w-sm -mt-5 rounded-b-3xl shadow-card z-10
    }

    .card-blocked-text {
        @apply pt-8 2xs:pt-9 pb-3 2xs:pb-4 bg-gradient-to-b from-card-blocked-area-top to-card-blocked-area-bottom text-white font-inter font-bold text-lg 2xs:text-xl text-center rounded-b-3xl z-10
    }

    .card-button-area {
        @apply mx-8 mt-5 pb-8
    }

    .card-button {
        -webkit-tap-highlight-color: transparent;
        @apply w-full py-2.5 2xs:py-3 px-3.5 bg-accent-color text-white font-inter font-bold text-sm 2xs:text-lg xs:text-xl border-t border-t-white border-solid rounded-lg shadow-button active:translate-y-[3px]
    }

    .card-promo-code-button-area {
        @apply relative grid grid-rows-2 grid-cols-1 mx-auto mt-4 -mb-4 w-[140px] h-auto text-subscription-gray font-inter font-bold text-center text-xs z-10
    }

    .card-promo-code-button {
        -webkit-tap-highlight-color: transparent;
        @apply absolute inset-0 h-full w-full z-20
    }

    .card-promo-code-button-arrow-svg {
        @apply mx-auto mt-0.5 max-w-[13%] fill-subscription-gray duration-500
    }

    .loader-overlay {
        @apply fixed inset-0 bg-loader-overlay z-50
    }

    .product-filter-button-container {
        @apply grow
    }

    .product-filter-button-grid-container {
        @apply h-[85%] pt-[5px]
    }

    .product-filter-button-area {
        @apply relative grid grid-rows-1 grid-cols-2 mx-auto w-[100%] h-[100%] bg-gradient-to-t from-card-bg-bottom to-card-bg-top border-t border-t-white border-solid rounded-3xl shadow-card
    }

    .product-filter-button {
        -webkit-tap-highlight-color: transparent;
        @apply col-span-2 w-[100%] py-6 rounded-3xl z-30
    }

    .product-filter-button-slider {
        @apply absolute inset-y-1 left-1 w-[50%] bg-accent-color rounded-3xl
    }

    .product-filter-button-text-annual {
        @apply absolute col-start-1 col-end-1 inset-0 py-1.5 pl-1.5 text-white font-inter font-bold text-lg text-center z-10
    }

    .product-filter-button-text-monthly {
        @apply absolute col-start-2 inset-0 py-1.5 pr-1.5 text-subscription-gray font-inter font-bold text-lg text-center z-10
    }

    .product-page-footer-container {
        @apply relative z-20
    }

    .product-page-footer-area {
        @apply absolute inset-0 flex justify-center items-center
    }

    .product-page-footer {
        @apply grid grid-rows-1 grid-cols-4 grid-flow-col gap-x-3 mt-[9%]
    }

    .stripe-account-button-container {
        @apply relative basis-1/5 z-20
    }

    .stripe-account-button-area {
        @apply relative w-[50px] h-[50px] mx-auto bg-gradient-to-t from-card-bg-bottom to-card-bg-top border border-white border-solid rounded-full shadow-card z-20
    }

    .stripe-account-button {
        -webkit-tap-highlight-color: transparent;
        @apply absolute inset-0 h-full w-full bg-transparent z-40
    }

    .stripe-account-button-svg {
        @apply absolute inset-0 mx-auto mt-[10%] max-w-[75%] fill-black z-30
    }
}